.field {
  background-image: url("/public/images/field-green.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.pattern {
  background-image: url("/public/images/pattern.svg");
  background-repeat: repeat;
  background-size: 11px;
}
.scroll-styled-hidden::-webkit-scrollbar {
  display: none !important;
}
